<template>

  <div id="sidecarNav">


    <div id="mobileNavWrapper" class="nav-wrapper" data-content-field="navigation-mobileNav">
      <nav id="mobileNavigation">
        <div class="index homepage" :class="{active:path=='/'}">
          <a href="/">
            Home
          </a>
        </div>
        <div class="folder"
          :class="{active:['/offerings','/cfd','/shares','/spread-betting','/forex','/commodities'].includes(path)}">
          <div class="folder-toggle" data-href="/invest-1">Invest</div>
          <div class="subnav">
            <div class="collection" :class="{active:path=='/offerings'}">
              <a href="/offerings">
                Our Offerings
              </a>
            </div>
            <div class="collection" :class="{active:path=='/cfd'}">
              <a href="/cfd">
                CFD
              </a>
            </div>
            <div class="collection" :class="{active:path=='/shares'}">
              <a href="/shares">
                Shares
              </a>
            </div>
            <div class="collection" :class="{active:path=='/spread-betting'}">
              <a href="/spread-betting">
                Spread Betting
              </a>
            </div>
            <div class="collection" :class="{active:path=='/forex'}">
              <a href="/forex">
                Forex
              </a>
            </div>
            <div class="collection" :class="{active:path=='/commodities'}">
              <a href="/commodities">
                Commodities
              </a>
            </div>
          </div>
        </div>
        <div class="folder" :class="{active:path=='/our-firm'}">
          <div class="folder-toggle" data-href="/our-firm-1">Our Firm</div>
          <div class="subnav">
            <div class="external">
              <a href="/our-firm#message-from-the-md">
                Message from the MD
              </a>
            </div>
            <div class="external">
              <a href="/our-firm#about-us">
                About Us
              </a>
            </div>


          </div>
        </div>
        <div class="collection" :class="{active:path=='/workshops'}">
          <a href="/workshops">
            Workshops
          </a>
        </div>
        <div class="collection" :class="{active:path=='/research'}">
          <a href="/research">
            Research
          </a>
        </div>
        <div class="index" :class="{active:path=='/contactus'}">
          <a href="/contactus">
            Contact Us
          </a>
        </div>
        <div class="collection">
          <a href="/rsvp">
            Open an Account
          </a>
        </div>
      </nav>
    </div>
  </div>
  <div id="siteWrapper" class="clearfix">
    <div class="sqs-cart-dropzone"></div>
    <layout-header></layout-header>
    <layout-footer></layout-footer>
  </div>
    <home-popup></home-popup>

    <div class="service" @click="openService">
      <img src="@/assets/images/kf.png">
    </div>
</template>

<script setup>
  import LayoutHeader from "@/components/LayoutHeader";
  import LayoutFooter from "@/components/LayoutFooter";
  import homePopup from "@/components/homePopup";
  import { onMounted, ref } from "vue";
  import { useRouter } from "vue-router"
  const path = ref()
  onMounted(()=>{
    const router = useRouter();
    setTimeout(()=>{
      path.value = router.currentRoute.value.path
    },500)
    $('.folder-toggle').click(function(){
      $('.folder-toggle').removeClass('active')
      $(this).toggleClass('active')
    })
  })
  const openService = ()=>{
    LiveChatWidget.call("maximize");
  }

</script>

<style lang="scss">
  .service{
    position: fixed;
    right: 50px;
    bottom: 50px;
    color: red;
    z-index: 111;
    @media screen and (max-width:640px){
      right: 10px;
      bottom: 10px;
    }
    img{
      width: 50px;
      box-shadow: 0px 0px 10px 0px #c6c6c6;
      border-radius: 25px;
      cursor: pointer;
    }
  }
</style>